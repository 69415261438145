
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        















































.icons-book {
  padding-top: $spacing * 8;
}

.icons-book-inner {
  max-width: 85rem;
  margin: auto;
}

.icons-book__title {
  margin-bottom: $spacing * 3;
}

.icons-book__content {
  width: 100%;
}
.icons-book__section {
  margin-bottom: $spacing * 2;
}

.icons-book__grid {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
  width: 100%;

  @include mq(m) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 3.6rem;
  }
}

.icons-book__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  border: 1px solid $c-gray-lightest;
  border-radius: 10px;

  svg {
    max-width: 80%;
    max-height: 5.5rem;
  }
}

.icons-book__label {
  margin-top: $spacing * 0.75;
  font-family: $ff-alt;

  div {
    margin-top: $spacing * 0.25;
    color: $c-gray-light;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-transform: uppercase;
  }

  div:first-child {
    color: black;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
